
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { mdiPlus, mdiChevronUp, mdiChevronDown, mdiFilter } from '@mdi/js'
import { isEqual } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'
import CommonDotIndicator from '@/components/common/CommonDotIndicator.vue'

import { useFilter } from '@/composables/useFilter'

import { dateDashNotationToDot } from '@/utils/convertDate'
import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { handleError } from '@/utils/handleError'

import { useDeleteComplianceCheck, useGetComplianceChecks } from '@/api/complianceCheck'

import { PersonOutput } from '@/api/types/person'
import { Rights } from '@/api/types/right'
import { ComplianceCheckOutput } from '@/api/types/complianceCheck'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY } from '@/views/persons/views/personProfile/components/lowerPersonProfile/components/compliance/types'

export default defineComponent({
  name: 'Compliance',
  components: {
    CommonTableView,
    CommonDotIndicator,
    AddEditComplianceCheckDialog: () =>
      import(
        '@/views/persons/views/personProfile/components/lowerPersonProfile/components/compliance/components/AddEditComplianceCheckDialog.vue'
      ),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonInfoDialog: () => import('@/components/common/CommonInfoDialog.vue'),
    CommonFilesDialog: () => import('@/components/common/CommonFilesDialog.vue'),
    ComplianceFilterBar: () =>
      import(
        '@/views/persons/views/personProfile/components/lowerPersonProfile/components/compliance/components/ComplianceFilterBar.vue'
      ),
  },
  props: {
    person: {
      type: Object as PropType<PersonOutput>,
      default: null,
    },
  },
  setup: (props, { root }) => {
    const TABLE_HEADERS = [
      {
        text: root.$t('persons.profile.tabMenu.compliance.table.col.complianceCheck'),
        value: 'complianceCheck',
        sortable: false,
      },
      {
        text: root.$t('persons.profile.tabMenu.compliance.table.col.compliancePoints'),
        value: 'compliancePoints',
        sortable: false,
      },
      {
        text: root.$t('persons.profile.tabMenu.compliance.table.col.compliancePeriod'),
        value: 'compliancePeriodDate',
        sortable: false,
      },
      {
        text: root.$t('persons.profile.tabMenu.compliance.table.col.complianceSignedBy'),
        value: 'complianceSignedBy.name',
        sortable: false,
      },
      {
        text: root.$t('persons.profile.tabMenu.compliance.table.col.comlianceFilesCount'),
        value: 'filesCount',
        sortable: false,
      },
      {
        text: root.$t('persons.profile.tabMenu.compliance.table.col.actions'),
        value: 'actions',
        sortable: false,
      },
    ]

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.ValidUntilFrom,
        value: null,
        label: root.$t('persons.profile.tabMenu.compliance.filters.validUntilFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.ValidUntilTo,
        value: null,
        label: root.$t('persons.profile.tabMenu.compliance.filters.validUntilTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init,
      true
    )

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    setSort(vuetifyTableOptions, { by: 'complianceCheck', desc: false })

    const {
      exec: getComplianceChecks,
      data: complianceChecks,
      isLoading: isLoadingComplianceChecks,
      paginationResponse,
    } = useGetComplianceChecks()

    const totalComplianceChecks = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      if (!props.person?.id) return

      await getComplianceChecks({
        params: { ...paginationParams.value, ...filterFieldsObject, personIds: props.person.id },
      })
    }

    const isAddComplianceCheckModalOpen = ref(false)

    const isEditMode = ref(false)

    const activeComplianceCheck = ref<ComplianceCheckOutput | null>(null)

    function onDblClickRow(_, { item: complianceCheck }: { item: ComplianceCheckOutput }): void {
      if (hasSufficientRights(Rights.COMPLIANCE_CHECK_UPDATE) && hasSufficientRights(Rights.BASIC_READ)) {
        isEditMode.value = true

        isAddComplianceCheckModalOpen.value = true

        activeComplianceCheck.value = { ...complianceCheck }
      }
    }

    const isDeleteComplianceCheckDialogOpen = ref(false)

    function onClickDelete(complianceCheck: ComplianceCheckOutput): void {
      activeComplianceCheck.value = { ...complianceCheck }

      isDeleteComplianceCheckDialogOpen.value = true
    }

    const { deleteComplianceCheck } = useDeleteComplianceCheck()

    async function onDeleteComplianceCheck(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteComplianceCheck(activeComplianceCheck.value!.id)
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteComplianceCheckDialogOpen.value = false

      activeComplianceCheck.value = null

      debouncedCb.value()
    }

    const isRowInfoDialogOpen = ref(false)

    const isFilesDialogOpen = ref(false)

    const PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG: (keyof ComplianceCheckOutput)[] = [
      'createdBy',
      'createdAt',
      'updatedBy',
      'updatedAt',
    ]

    function onClickInfo(complianceCheck: ComplianceCheckOutput): void {
      activeComplianceCheck.value = { ...complianceCheck }

      isRowInfoDialogOpen.value = true
    }

    function onClickFiles(complianceCheck: ComplianceCheckOutput): void {
      activeComplianceCheck.value = { ...complianceCheck }

      isFilesDialogOpen.value = true
    }

    function onCloseAddEditDeleteInfoFilesDialog(): void {
      isAddComplianceCheckModalOpen.value = false

      isDeleteComplianceCheckDialogOpen.value = false

      isRowInfoDialogOpen.value = false

      isFilesDialogOpen.value = false

      activeComplianceCheck.value = null
    }

    function calcCompliancePeriodDate({ complianceCheck, compliancePeriod }: ComplianceCheckOutput): string {
      const complianceCheckDate = new Date(complianceCheck)

      complianceCheckDate.setMonth(complianceCheckDate.getMonth() + compliancePeriod)

      return complianceCheckDate.toLocaleDateString('de', {
        dateStyle: 'medium',
      })
    }

    function calcCompliancePointsColor(points: number): string {
      if (points >= 0 && points <= 50) {
        return 'green'
      } else if (points >= 51 && points <= 110) {
        return 'yellow'
      } else if (points >= 111) {
        return 'red'
      } else {
        return 'grey'
      }
    }

    return reactive({
      icons: {
        mdiPlus,
        mdiChevronUp,
        mdiChevronDown,
        mdiFilter,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,

        FILTER_FIELDS,
      },
      state: {
        complianceChecks,
        isLoadingComplianceChecks,

        totalComplianceChecks,
        vuetifyTableOptions,

        isAddComplianceCheckModalOpen,
        activeComplianceCheck,
        isEditMode,

        isFilterDropdownOpen,

        isDeleteComplianceCheckDialogOpen,

        isRowInfoDialogOpen,

        isFilesDialogOpen,
      },
      listeners: {
        onDblClickRow,

        onClickDelete,

        onCloseAddEditDeleteInfoFilesDialog,

        onDeleteComplianceCheck,

        onClickInfo,

        onClickFiles,

        onToggleFilterDropdown,
      },
      functions: {
        dateDashNotationToDot,
        hasSufficientRights,

        calcCompliancePointsColor,

        debouncedCb,

        calcCompliancePeriodDate,
      },
    })
  },
})
