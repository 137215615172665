var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', {
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.complianceChecks,
      "loading": _vm.state.isLoadingComplianceChecks,
      "server-items-length": _vm.state.totalComplianceChecks,
      "options": _vm.state.vuetifyTableOptions,
      "with-info": "",
      "with-files": "",
      "is-common-view": false
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "dblclick:row": _vm.listeners.onDblClickRow,
      "click:info": function clickInfo($event) {
        return _vm.listeners.onClickInfo($event);
      },
      "click:files": function clickFiles($event) {
        return _vm.listeners.onClickFiles($event);
      },
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onClickDelete($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "pb-3 ma-0",
          attrs: {
            "align": "center"
          }
        }, [_c('v-btn', {
          staticClass: "mr-5 rounded-lg white",
          attrs: {
            "text": "",
            "color": "primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              return _vm.listeners.onToggleFilterDropdown();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiFilter) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.filter')) + " "), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.state.isFilterDropdownOpen ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown) + " ")])], 1), _vm.functions.hasSufficientRights(_vm.constants.Rights.COMPLIANCE_CHECK_CREATE) && _vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
          staticClass: "ml-auto rounded-lg",
          attrs: {
            "color": "primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isAddComplianceCheckModalOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.compliance.actions.addComplianceCheck')) + " ")], 1) : _vm._e()], 1), _c('v-expand-transition', {
          attrs: {
            "mode": "in-out"
          }
        }, [_vm.state.isFilterDropdownOpen ? _c('ComplianceFilterBar', {
          staticClass: "d-flex align-center",
          attrs: {
            "fields": _vm.constants.FILTER_FIELDS
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "compliance-points",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "justify-end w-1/4 d-flex align-center"
        }, [_vm._v(" " + _vm._s(item.compliancePoints) + " "), _c('CommonDotIndicator', {
          staticClass: "ml-2",
          attrs: {
            "color": _vm.functions.calcCompliancePointsColor(item.compliancePoints)
          }
        })], 1)];
      }
    }, {
      key: "compliance-period-date",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_vm._v(" " + _vm._s(_vm.functions.calcCompliancePeriodDate(item)) + " ")])];
      }
    }, {
      key: "item.actions.files",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.FILE_READ) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "item.actions.delete",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.COMPLIANCE_CHECK_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }], null, true)
  }, [_vm.state.isAddComplianceCheckModalOpen ? _c('AddEditComplianceCheckDialog', {
    attrs: {
      "is-edit-mode": _vm.state.isEditMode,
      "compliance-check-to-edit": _vm.state.activeComplianceCheck
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoFilesDialog();
      }
    },
    model: {
      value: _vm.state.isAddComplianceCheckModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddComplianceCheckModalOpen", $$v);
      },
      expression: "state.isAddComplianceCheckModalOpen"
    }
  }) : _vm._e(), _vm.state.isRowInfoDialogOpen ? _c('CommonInfoDialog', {
    attrs: {
      "entity": _vm.state.activeComplianceCheck,
      "properties-to-show": _vm.constants.PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,
      "custom-parent-key": "persons",
      "translation-key": 'profile.tabMenu.compliance'
    },
    on: {
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoFilesDialog();
      }
    },
    model: {
      value: _vm.state.isRowInfoDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isRowInfoDialogOpen", $$v);
      },
      expression: "state.isRowInfoDialogOpen"
    }
  }) : _vm._e(), _vm.state.isFilesDialogOpen ? _c('CommonFilesDialog', {
    attrs: {
      "entity": _vm.state.activeComplianceCheck,
      "entity-id": _vm.state.activeComplianceCheck.id,
      "has-download-right": _vm.functions.hasSufficientRights(_vm.constants.Rights.COMPLIANCE_CHECK_READ_ALL) || _vm.functions.hasSufficientRights(_vm.constants.Rights.COMPLIANCE_CHECK_READ_OWN),
      "entity-name": "COMPLIANCE_CHECK"
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoFilesDialog();
      }
    },
    model: {
      value: _vm.state.isFilesDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isFilesDialogOpen", $$v);
      },
      expression: "state.isFilesDialogOpen"
    }
  }) : _vm._e(), _vm.state.isDeleteComplianceCheckDialogOpen ? _c('CommonDeleteDialog', {
    on: {
      "delete": function _delete($event) {
        return _vm.listeners.onDeleteComplianceCheck();
      },
      "cancel": function cancel($event) {
        return _vm.listeners.onCloseAddEditDeleteInfoFilesDialog();
      }
    },
    model: {
      value: _vm.state.isDeleteComplianceCheckDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteComplianceCheckDialogOpen", $$v);
      },
      expression: "state.isDeleteComplianceCheckDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.compliance.form.delete.text')) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }